import React from 'react';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { Currency } from 'api/requests/site/site.types';
import { useCurrency } from 'hooks/useCurrency/useCurrency';

import { PriceProps } from './Price.types';
import { Price } from './Price';

export const LocalizedPrice = (props: Omit<PriceProps, 'currency' | 'locale'>) => {
  const { user } = useAuthState();
  const currencyData = useCurrency();

  const currency = currencyData.activeCurrency.code || Currency.DOLLAR;
  const locale = user?.localization.languageCode || 'en';

  return <Price currency={currency} locale={locale} {...props} />;
};
