import React from 'react';
import classNames from 'classnames';

import { RibbonProps } from './Ribbon.types';
import * as S from './Ribbon.styles';

export const Ribbon = ({ text, emphasize, placement, color, size = 'large', style, className }: RibbonProps) => {
  const textColor = color === 'gray' ? 'common.black' : 'common.white';

  return (
    <S.Ribbon
      className={classNames(
        className,
        color && [`${color}Color`],
        placement && [`${placement}Placement`, 'ribbonCorner'],
        emphasize && 'emphasize',
        size && `${size}Size`,
      )}
      style={style}
    >
      <S.TypographyTitle color={textColor}>{text}</S.TypographyTitle>
    </S.Ribbon>
  );
};
