import { styled, Box, Typography } from '@mui/material';

export const Ribbon = styled(Box)(({ theme }) => ({
  letterSpacing: '.9px',
  position: 'absolute',
  top: theme.spacing(-1.5),
  left: theme.spacing(-1.25),
  zIndex: 2,
  height: theme.spacing(3.75),
  padding: theme.spacing(0, 2, 0, 2.5),
  boxShadow: theme.shadows[5],
  fontSize: 12,
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  background: 'currentColor',
  '&::before': {
    content: '""',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: theme.spacing(0, 1.25, 1.25, 0),
    position: 'absolute',
    bottom: theme.spacing(-1.25),
    left: 0,
    filter: 'brightness(75%)',
    borderColor: `transparent currentColor transparent transparent`,
  },
  '&::after': {
    content: '""',
    right: theme.spacing(-0.625),
    top: 0,
    display: 'inline-block',
    position: 'absolute',
    borderRight: `${theme.spacing(0.625)} solid transparent`,
    borderTop: `${theme.spacing(1.875)} solid currentColor`,
    borderBottom: `${theme.spacing(1.875)} solid currentColor`,
  },
  '&.smallSize': {
    height: theme.spacing(2.5),
    padding: theme.spacing(0, 1, 0, 1.5),
    '&::after': {
      borderTopWidth: theme.spacing(1.25),
      borderBottomWidth: theme.spacing(1.25),
    },
  },
  '&.emphasize': {
    fontSize: 14,
  },
  '&.purpleColor': {
    color: theme.palette.primary.main,
  },
  '&.blueColor': {
    color: theme.palette.blue.light,
  },
  '&.blueLightColor': {
    color: theme.palette.blue.light,
  },
  '&.blueDarkColor': {
    color: '#113cb9',
  },
  '&.pinkColor': {
    color: theme.palette.pink.main,
  },
  '&.redColor': {
    color: theme.palette.red.dark,
  },
  '&.orangeColor': {
    color: theme.palette.orange.main,
  },
  '&.grayColor': {
    color: theme.palette.border.main,
  },
  '&.violetColor': {
    color: theme.palette.violet.main,
  },
  '&.plumColor': {
    color: theme.palette.plum.main,
  },
  '&.yellowColor': {
    color: theme.palette.yellow.dark,
  },
  '&.greenColor': {
    color: theme.palette.secondary.light,
  },
  '&.ribbonCorner': {
    width: theme.spacing(21.5),
    top: theme.spacing(3),
    letterSpacing: 0,
    boxShadow: theme.shadows[1],
    fontWeight: theme.typography.fontWeightSemiBold,
    '&::after': {
      content: 'none',
    },
    '&::before': {
      content: 'none',
    },
  },
  '&.rightPlacement': {
    transform: 'rotate(45deg)',
    left: 'unset',
    right: theme.spacing(-5.75),
  },
  '&.leftPlacement': {
    transform: 'rotate(-45deg)',
    left: theme.spacing(-5.75),
  },
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 12,
  lineHeight: 2,
  padding: theme.spacing(0, 0.5),
}));
