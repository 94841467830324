import React from 'react';
import { default as ReactCountdown, CountdownRenderProps } from 'react-countdown';
import classNames from 'classnames';

import { useMounted } from 'hooks/useMounted/useMounted';

import { CountdownProps } from './Countdown.types';

export const Countdown = (props: CountdownProps) => {
  const {
    isBoxed,
    className,
    prefix,
    isEmphasized,
    style,
    separator = ' ',
    dateFormat = { day: 'd', hour: 'h', minute: 'm', second: 's' },
    daysInMinutes = false,
    ...countdownProps
  } = props;
  const isMounted = useMounted();

  const renderCountdown = ({ days, hours, minutes, seconds }: CountdownRenderProps) => {
    const daysValue = isMounted ? days : 0;
    const hoursValue = isMounted ? hours : 0;
    const minutesValue = isMounted ? minutes : 0;
    const secondsValue = isMounted ? seconds : 0;

    const countdownData = [
      {
        format: dateFormat.day,
        time: daysValue,
      },
      {
        format: dateFormat.hour,
        time: hoursValue,
      },
      {
        format: dateFormat.minute,
        time: minutesValue,
      },
      {
        format: dateFormat.second,
        time: secondsValue,
      },
    ];

    if (isBoxed) {
      return (
        <div>
          {prefix && <h4 className="flex font-bold uppercase">{prefix} </h4>}
          <div className="flex gap-[6px]">
            {countdownData.map((date, idx) => (
              <div
                className="flex h-[85px] w-[25%] flex-col items-center justify-center rounded border border-primary-500"
                key={idx}
              >
                <span className="text-3xl font-bold text-primary-500">{date.time}</span>
                <span className="text-sm text-primary-500">{`${date.format}${separator}`}</span>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <p className={classNames(isEmphasized && 'text-red-700', 'text-xs', className)} style={style}>
        {prefix && `${prefix} `}
        {daysInMinutes
          ? `${minutesValue}${dateFormat.minute}${separator}${secondsValue}${dateFormat.second}`
          : `${daysValue}${dateFormat.day}${separator}${hoursValue}${dateFormat.hour}${separator}${minutesValue}${dateFormat.minute}${separator}${secondsValue}${dateFormat.second}`}
      </p>
    );
  };

  return <ReactCountdown renderer={renderCountdown} {...countdownProps} />;
};
