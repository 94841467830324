import { Currency } from 'api/requests/site/site.types';

export function usePrice({
  value,
  currency = Currency.DOLLAR,
  noDecimalZeros,
}: {
  value?: number | string;
  currency?: Currency;
  noDecimalZeros?: boolean;
}) {
  if (value) {
    const maximumFractionDigits = Number.isInteger(Number(value)) && noDecimalZeros ? 0 : 2;

    const price = new Intl.NumberFormat('en', {
      style: 'currency',
      currency,
      minimumFractionDigits: maximumFractionDigits,
      maximumFractionDigits,
    }).format(Number(value));

    return price;
  }
}
