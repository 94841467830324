import { SearchProduct } from 'api/requests/search/search.types';
import { UserAvatar } from 'api/requests/user/user.types';

export enum ProductType {
  FONTS = 'Fonts',
  GRAPHICS = 'Graphics',
  CRAFTS = 'Crafts',
  EMBROIDERY = 'Embroidery',
  BUNDLES = 'Bundles',
  CLASSES = 'Classes',
  COMMUNITY = 'Community',
}

export type Product = Omit<SearchProduct, '__position' | '__queryID'> & {
  showDiscountPrice: boolean;
  favoriteCount: number;
  isUserFavorite: boolean;
  countdown: string | undefined;
  countdownPrefix: string;
  ribbonTitle: string;
  ribbonColor: string;
  tooltipText: string;
  isFree: boolean;
};

export type ProductDetail = {
  totalCount: number;
  isUserFavorite: boolean;
  users: {
    shopSlug: string;
    avatar: UserAvatar;
  }[];
  countOverflow: number;
};
