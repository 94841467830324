import React from 'react';

import { Currency } from 'api/requests/site/site.types';
import { usePrice } from 'hooks/usePrice/usePrice';

import { PriceProps } from './Price.types';
import * as S from './Price.styles';

export const Price = (props: PriceProps) => {
  const {
    value,
    currency = Currency.DOLLAR,
    crossed,
    noDecimalZeros,
    component = 'span',
    variant = 'inherit',
    ...typographyProps
  } = props;
  const price = usePrice({ value, currency, noDecimalZeros });

  if (!price) {
    return null;
  }

  return (
    <S.TypographyStyled variant={variant} component={component} crossed={crossed} {...typographyProps}>
      {price}
    </S.TypographyStyled>
  );
};
