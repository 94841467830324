import { useState } from 'react';
import classNames from 'classnames';

import { FutureImage } from 'components/elements/atoms/image/FutureImage';
import { Link } from 'components/elements/atoms/link/Link';
import { ProductType } from 'api/requests/products/products.types';
import { Translations, useFormatMessage } from 'utilities/i18n';
import { LocalizedPrice } from 'components/elements/atoms/price/LocalizedPrice';
import { Icon } from 'components/elements/atoms/icon/Icon';
import { Ribbon } from 'components/elements/molecules/ribbon/Ribbon';
import { Tooltip } from 'components/elements/molecules/tooltip/Tooltip';
import {
  creationTypeToToolName,
  SparkFeedPromptActionButtons,
} from 'components/elements/molecules/sparkFeedCard/SparkFeedCard';
import { CreationType } from 'generated/graphql';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';
import { useCurrentCurrency } from 'hooks/useCurrency/useCurrency';
import { SubscriptionType } from 'api/requests/subscriptions/subscriptions.types';
import { Countdown } from 'components/elements/atoms/countdown/Countdown';
import { mapCategoryPageTypeToCategoryDetails } from 'components/pages/contentApi/pages/config';
import { STOCKPHOTOS_AUTHOR_ID } from 'components/pages/stockphotos/pages/config';

import { SearchResultProductProps } from './SearchResultProduct.types';

function lowercaseFirstLetter(text: string) {
  return text.charAt(0).toLowerCase() + text.slice(1);
}

export function SearchResultProduct(props: SearchResultProductProps) {
  const {
    productUrl,
    image,
    name,
    category,
    subcategory = '',
    userHasAccess,
    price,
    discountedPrice,
    promoActive = false,
    discount = 0,
    onClick,
    aiPrompt,
    extraSettings,
    designer,
    countdown,
  } = props;
  const t = useFormatMessage();
  const [showPromptContent, setShowPromptContent] = useState(false);
  const user = useOptionalUser();
  const currency = useCurrentCurrency();
  const isPremium = designer?.isPremium || false;
  const premiumUrl = isPremium ? designer && designer.designerUrl.split('designer')[1] : '';

  const hadAllAccess =
    user?.settings.hasAllAccess !== true &&
    Boolean(user?.subscriptions.some(sub => sub.type === SubscriptionType.ALL_ACCESS));

  const enablePremiumPrices = isPremium && !hadAllAccess;

  const handleProductClick = () => {
    if (!onClick) {
      return;
    }

    onClick();
  };

  const renderPriceSection = (tooltipText: string | undefined) => {
    if (userHasAccess && !isPremium) {
      if (tooltipText) {
        return (
          <Tooltip
            style={{ display: 'inline' }}
            disableInteractive
            title={tooltipText}
            placement="top"
            variant="primary"
          >
            <Icon icon="check-circle" size="lg" className="text-secondary-500" />
          </Tooltip>
        );
      }

      return <Icon icon="check-circle" size="lg" className="text-secondary-500" />;
    }

    if (currency !== 'USD') {
      return null;
    }

    if (promoActive) {
      return (
        <span className="font-bold text-[#ff0000]">
          {t('common.discount_price', {
            values: {
              percent: discount,
              price: <LocalizedPrice className="text-black-500" value={price} crossed={true} />,
            },
          })}
        </span>
      );
    }

    if ((discountedPrice === 0 && price > 0) || price === 0) {
      return null;
    }

    if (discountedPrice !== price || enablePremiumPrices) {
      const discountedPriceValue = enablePremiumPrices ? discountedPrice * 0.7 : discountedPrice;

      return (
        <span className="flex gap-[5px]">
          <LocalizedPrice className="font-bold text-red-700 underline" value={discountedPriceValue} />
          <LocalizedPrice className="font-bold text-black-500" value={price} crossed={true} />
        </span>
      );
    }

    return <LocalizedPrice className="font-bold text-black-500" value={price} />;
  };

  const sparkTool = extraSettings?.sparkType ? (lowercaseFirstLetter(extraSettings.sparkType) as CreationType) : 'art';

  const isClass = category === ProductType.CLASSES;

  const PRODUCT_CATEGORY_MAP: Record<string, string> = {
    Fonts: 'common.product.fonts',
    Graphics: 'common.product.graphics',
    Embroidery: 'component.product_card.all_subscription',
    Crafts: 'common.product.crafts',
    Bundles: 'component.product_card.all_subscription',
    Classes: 'common.product.classes',
    Community: 'common.product.community',
    [mapCategoryPageTypeToCategoryDetails.png.authorId]: 'component.product_card.all_subscription',
    [STOCKPHOTOS_AUTHOR_ID]: 'component.product_card.all_subscription',
  } as const;

  const categoryType = PRODUCT_CATEGORY_MAP[category] && t(PRODUCT_CATEGORY_MAP[category] as Translations);

  return (
    <div
      className="relative flex w-full flex-col shadow-lg"
      onClick={handleProductClick}
      onMouseEnter={() => setShowPromptContent(true)}
      onMouseLeave={() => setShowPromptContent(false)}
    >
      {category === ProductType.BUNDLES ? (
        <Ribbon text={t('common.product.bundles')} color="plum" className="!left-0 !top-0 rounded-tl before:!hidden" />
      ) : null}

      {isPremium ? (
        <Ribbon
          text={t('common.product.cf_premium')}
          color="blueDark"
          className="!left-0 !top-0 rounded-tl before:!hidden"
        />
      ) : null}

      <div className="relative">
        <Link href={productUrl} target="_blank">
          <FutureImage
            src={image}
            alt={name}
            height={220}
            width={274}
            className="flex w-full"
            imageClassName={classNames(
              category === mapCategoryPageTypeToCategoryDetails.png.authorId && 'max-w-[300px]',
              'w-full h-auto rounded-t',
            )}
          />
          {isClass && extraSettings ? (
            <>
              <div className="absolute right-0 top-[5px] rounded-bl rounded-tl bg-white px-[7px] py-[3px] text-base">
                <Icon icon="video" color="#2a1f3f" />
              </div>
              {extraSettings.level ? (
                <div
                  className={classNames(
                    extraSettings.level === 'Beginner' && 'bg-secondary-500 hover:bg-secondary-700',
                    extraSettings.level === 'Intermediate' && 'bg-[#f7b500] hover:bg-[#e0a501]',
                    extraSettings.level === 'Advanced' && 'bg-red-700 hover:bg-[#a91f42]',
                    'absolute bottom-[10px] left-[10px] rounded-[2px] px-2 py-[3px] text-xs font-semibold text-white',
                  )}
                >
                  {extraSettings.level}
                </div>
              ) : null}

              <div
                className={classNames(
                  extraSettings.source === 'Sourced' && 'hidden',
                  'absolute bottom-[10px] right-[10px] rounded-[2px] bg-plum-500 px-2 py-[3px] text-xs font-semibold text-white hover:bg-plum-700',
                )}
              >
                {`CF ${extraSettings.source}`}
              </div>
            </>
          ) : null}
        </Link>
        {aiPrompt &&
        extraSettings?.sparkType !== undefined &&
        showPromptContent &&
        user &&
        user?.capabilities.indexOf('dashboard_access') !== -1 ? (
          <div className="absolute bottom-2 left-2 flex w-[calc(100%-20px)] flex-col gap-[10px] rounded bg-[#4d134b] p-[5px] opacity-80">
            <div className="flex flex-row items-center justify-between text-xs text-[#c0c0c0]">
              <div className="flex gap-2">
                {t('spark_feed_card.prompt_header')}
                <Tooltip
                  style={{ display: 'inline' }}
                  disableInteractive
                  title={t('spark_feed_card.prompt_visible_tooltip')}
                  placement="top"
                  variant="primary"
                >
                  <Icon icon={['fas', 'globe']} />
                </Tooltip>
              </div>
              <SparkFeedPromptActionButtons
                prompt={aiPrompt}
                buttonColor="#c0c0c0"
                creationType={sparkTool}
                tooltipText={{
                  copy: t('page.search.product_card.copy_tooltip'),
                  redirect: t('page.search.product_card.try_tooltip', {
                    values: { tool: creationTypeToToolName[sparkTool] },
                  }),
                }}
              />
            </div>
            <span className="text-xs text-white">{aiPrompt}</span>
            {extraSettings.sparkModelType && extraSettings.sparkModelVersion ? (
              <span className="text-xs text-white">
                {t('page.search.product_card.sparkModel', {
                  values: {
                    b: (text: string) => <strong>{text}</strong>,
                    type: extraSettings.sparkModelType,
                    version: extraSettings.sparkModelVersion,
                  },
                })}
              </span>
            ) : null}
          </div>
        ) : null}
        {countdown !== undefined ? (
          <div className="absolute bottom-0 right-0 z-[1] rounded-tl bg-white px-3 py-[2px] ">
            <Countdown date={countdown} prefix={t('common.gone_in')} isEmphasized />
          </div>
        ) : null}
      </div>

      <div className="flex w-full flex-col gap-3 rounded bg-white px-[10px] py-[5px]">
        {category !== ProductType.COMMUNITY ? (
          <h3 className="flex items-center justify-between gap-[10px]">
            <Link
              href={productUrl}
              underline="none"
              color="inherit"
              target="_blank"
              className="overflow-hidden text-ellipsis whitespace-nowrap font-bold hover:text-secondary-500"
            >
              {name}
            </Link>
            {isPremium ? (
              <span className="shrink-0">
                <Tooltip
                  style={{ display: 'inline' }}
                  disableInteractive
                  title={t('component.product_card.cf_premium')}
                  placement="top"
                  variant="cfPremium"
                >
                  <FutureImage
                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/CF-Premium_icon_Lock-Sparkle.svg`}
                    width={28}
                    height={28}
                    alt="CF Premium"
                  />
                </Tooltip>
              </span>
            ) : null}
          </h3>
        ) : null}
        <div className="flex items-center justify-between gap-[10px]">
          <span className="text-xs">
            {isPremium && designer ? (
              <>
                {t('component.post_card.details.author.by')}{' '}
                <Link underline="none" target="_blank" href={`/brand${premiumUrl}`}>
                  {designer.designerName}
                </Link>
              </>
            ) : (
              <>
                {t('common.in')}{' '}
                <Link
                  underline="none"
                  target="_blank"
                  href={
                    category !== ProductType.GRAPHICS
                      ? `/${
                          category === mapCategoryPageTypeToCategoryDetails.png.authorId
                            ? 'png'
                            : category.toLocaleLowerCase().replace(/\s/g, '-')
                        }/`
                      : `/subscriptions/graphics/${subcategory.toLocaleLowerCase().replace(/\s/g, '-')}/`
                  }
                >
                  {category !== ProductType.GRAPHICS
                    ? category === mapCategoryPageTypeToCategoryDetails.png.authorId
                      ? t('page.search.png_category')
                      : (category.charAt(0).toUpperCase() + category.slice(1)).replace(/-/g, ' ')
                    : subcategory.charAt(0).toUpperCase() + subcategory.slice(1)}
                </Link>
              </>
            )}
          </span>
          {renderPriceSection(
            isClass
              ? t('component.product_card.tooltip_classes')
              : t('component.product_card.tooltip', {
                  values: {
                    categoryType,
                  },
                }),
          )}
        </div>
      </div>
    </div>
  );
}
